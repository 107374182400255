<template>
  <div id="card">
    <div class="inner">
      <Transition mode="out-in">
        <router-view />
      </Transition>
    </div>
  </div>
</template>

<script>
import { irregular_verbs } from "../../irregular-verbs-table.js";

export default {
  name: "mainCard",
  components: {},

  mounted() {
    this.createProblems();
  },
  computed: {
    score() {
      return this.$store.state.score;
    },
    pb() {
      return this.$store.state.problems;
    },
  },
  methods: {
    createProblems() {
      const ps = irregular_verbs[1];
      this.$store.commit("setProblems", { ...ps });
    },
  },
};
</script>

<style lang="scss">
.cont {
  padding: 20px !important;
}
#card {
  border: 10px solid transparent;

  background: linear-gradient(rgb(255, 253, 181), rgb(255, 253, 181))
      padding-box,
    repeating-linear-gradient(
        -45deg,
        #f479b0 0,
        #f479b0 12.5%,
        rgb(255, 253, 181) 0,
        rgb(255, 253, 181) 25%,
        #62bcfc 0,
        #62bcfc 37.5%,
        rgb(255, 253, 181) 0,
        rgb(255, 253, 181) 50%
      )
      0 / 5em 5em;

  border-radius: 15px;
  width: 900px;
  height: 550px;

  @media all and (max-width: 900px) {
    width: 100%;
    height: calc(100vh - 70px);
    border: none;
    box-shadow: none;
    border-radius: 0;
  }

  box-shadow: 0px 4px 15px -5px #6d6d6d;

  .inner {
    border-radius: 10px;
    width: 100%;
    height: 100%;

    display: grid;
    place-items: center;

    @media all and (max-width: 900px) {
      overflow-y: scroll;
      width: 100%;
      margin: 0;
      border-radius: 0;
      height: calc(100vh - 70px);
      display: grid;
      place-items: start center;
      padding-top: 20px;
    }

    background-image: repeating-linear-gradient(
      #fefcec 0px,
      #fefcec 24px,
      #d8d6c7 25px
    );
  }

  * {
    box-sizing: border-box;
  }
  /* enter-active -> enter-to の順番で書くのが大事*/
  .v-enter-active {
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    opacity: 0;
    transform: translateY(10px);
  }
  .v-enter-to {
    opacity: 1;
    transform: translateY(0);
  }

  /* leave-active -> leave-to の順番で書くのが大事*/
  .v-leave-active {
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    opacity: 1;
    transform: translateY(0px);
  }
  .v-leave-to {
    opacity: 0;
    transform: translateY(-10px);
  }
}
</style>
