import Vue from "vue";
import App from "./App.vue";
import "normalize.css";
import "vue-hu-dialog/lib/vueHDialog.css";
import "./assets/scss/font.scss";
import store from "../store";
import VTooltip from "v-tooltip";
import router from "./router";
import ToggleButton from "vue-js-toggle-button";
import VueDialog from "vue-hu-dialog";

const options = {
  zIndex: 504,
  componentName: "VueDialog",
  propertyName: "hDialog",
  lang: "zh-cn",
  i18n: {
    // '确定': 'Confirm',
  },
};

Vue.use(ToggleButton);
Vue.use(VTooltip);
Vue.use(ToggleButton);
Vue.config.productionTip = false;
Vue.use(VueDialog, options);

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
