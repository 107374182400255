import Vue from "vue";
import Router from "vue-router";
import gameWindow from "./components/ui/listner";
import configWindow from "./components/ui/config";
import practiceWindow from "./components/ui/practice";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "main",
      component: configWindow,
      meta: { title: "不規則動詞活用表 発音チャレンジ", desc: "設定画面" },
    },
    {
      path: "/game",
      name: "game",
      component: gameWindow,
      meta: { title: "不規則動詞活用表 発音チャレンジ", desc: "ゲーム画面" },
    },
    {
      path: "/practice/:id",
      name: "practice",
      component: practiceWindow,
      meta: { title: "不規則動詞活用表 発音チャレンジ", desc: "ゲーム画面" },
    },
  ],
});
