<template>
  <div class="rootWindow">
    <div class="containee">
      <div class="section PC">
        <h1>不規則動詞活用表&emsp;発音チャレンジ</h1>
      </div>
      <div class="section SP">
        <h1>不規則動詞活用表 発音チャレンジ</h1>
      </div>
    </div>

    <div class="switched container">
      <div
        class="cat"
        v-for="cat in problemCats"
        :key="cat.id"
        :style="makeBgCOlor(levelTable[cat.id])"
      >
        <div class="wrap">
          <div class="title" @click="openList(cat.id)">{{ cat.name }}</div>
          <div class="practice" @click="practiceMode(cat.id)"></div>
          <toggle-button
            sync
            v-model="cat.isON"
            :color="getColorbyId(levelTable[cat.id])"
          >
          </toggle-button>
        </div>
      </div>
    </div>
    <div class="settings">
      <div class="container">
        <h2 class="section-title">出題数</h2>
        <div class="button-list">
          <button class="niceStyle maru" @click="setProblems(-5)">-5</button>
          <button class="niceStyle short">{{ problems }}</button>
          <button class="niceStyle maru" @click="setProblems(5)">+5</button>
        </div>
      </div>
      <div class="container">
        <button class="startButton" @click="makeProblemList">START</button>
      </div>
      <div class="container">
        <h2 class="section-title">出題範囲</h2>
        <div class="sap">
          <button
            class="cat"
            :style="isAllOn() ? 'background: #ffe347' : ''"
            @click="allOn()"
          >
            ALL ON
          </button>
          <button
            class="cat"
            :style="isAllOff() ? 'background: #ffe347' : ''"
            @click="allOff()"
          >
            ALL OFF
          </button>
        </div>
      </div>
    </div>
    <div class="line">
      <div class="practice" @click="practiceMode(cat.id)"></div>
      <div class="">練習モード。発音を何度でも試せます。</div>
    </div>
  </div>
</template>

<script>
import {
  irregular_verbs,
  lookUpTable,
  levelTable,
} from "../../irregular-verbs-table.js";
export default {
  name: "configWindow",
  props: {},
  asyncData() {
    return {};
  },
  data() {
    return {
      problemSelection: [],
      problemCats: [],
      problems: 20,
      levelTable: levelTable,
    };
  },
  computed: {
    prom() {
      return this.prompt;
    },

    listningCss() {
      return this.listning ? "listning" : "";
    },

    score() {
      return this.$store.state.score;
    },
    pb() {
      return this.$store.state.problems;
    },

    cnum() {
      return this.$store.state.currentNum;
    },
  },
  mounted() {
    irregular_verbs.forEach((Element, index) => {
      const data = {
        id: index,
        name: `${lookUpTable[index]}`,
        isON: true,
      };
      this.problemCats.push(data);
    });
  },
  created() {},
  methods: {
    aaaa() {
      irregular_verbs.forEach((Element) => {
        console.log(Element);
      });
    },
    openList(index) {
      this.$hDialog({
        title: lookUpTable[index],
        html: `<div class="cont">${this.irg(index)}</div>`,
        showMax: false,
        showFooter: false,
      });
    },
    irg(index) {
      let text = "";
      irregular_verbs[index].forEach((ele) => {
        text += ele.meaning + "<br>";
      });

      return text;
    },

    link(index) {
      return `practice/${index}`;
    },
    setProblems(num) {
      this.problems += num;
      if (this.problems < 5) {
        this.problems = 5;
      }
      if (this.problems > 100) {
        this.problems = 100;
      }
    },
    allOn() {
      this.problemCats.forEach((ele) => {
        ele.isON = true;
      });
    },

    makeBgCOlor(id) {
      return `border-color: ${this.getColorbyId(id)}`;
    },

    getColorbyId(id) {
      const colorList = [
        "lightskyblue",
        "#AACC66",
        "#ffe347",
        "orange",
        "tomato",
      ];

      return colorList[id];
    },

    allOff() {
      this.problemCats.forEach((ele) => {
        ele.isON = false;
      });
    },

    isAllOn() {
      let isAllOn = true;
      this.problemCats.forEach((ele) => {
        if (!ele.isON) {
          isAllOn = false;
        }
      });
      return isAllOn;
    },

    isAllOff() {
      let isAllOff = true;
      this.problemCats.forEach((ele) => {
        if (ele.isON) {
          isAllOff = false;
        }
      });
      return isAllOff;
    },
    makeProblemList() {
      this.problemSelection = [];
      this.problemCats.forEach((ele, index) => {
        if (ele.isON) {
          this.problemSelection.push(index);
        }
      });

      let probles = [];
      this.problemSelection.forEach((element) => {
        irregular_verbs[element].forEach((ele) => {
          probles.push(ele);
        });
      });

      for (let i = probles.length - 1; 0 < i; i--) {
        // 0〜(i+1)の範囲で値を取得
        let r = Math.floor(Math.random() * (i + 1));

        // 要素の並び替えを実行
        let tmp = probles[i];
        probles[i] = probles[r];
        probles[r] = tmp;
      }

      probles.splice(this.problems);

      this.$store.commit("setProblems", { ...probles });
      this.$store.commit("setScore", 0);
      this.$store.commit("setCurrentNum", 0);
      this.$store.commit("setTotal", this.problems);
      this.$router.push("/game");
    },

    practiceMode(index) {
      let probles = irregular_verbs[index];

      console.log(index);
      this.$store.commit("setScore", 0);
      this.$store.commit("setCurrentNum", 0);
      this.$store.commit("setTotal", probles.length);
      this.$store.commit("setProblems", { ...probles });
      this.$router.push(`practice/${index}`);
    },
  },
};
</script>

<style scoped lang="scss">
h1 {
  margin: 0;
  font-size: 2.2rem;
}

.section-title {
  text-align: center;
}

.PC {
  @media all and (max-width: 900px) {
    display: none;
  }
}

.SP {
  display: none;
  @media all and (max-width: 900px) {
    display: block;
  }
}

.containee {
  height: 130px;
  display: grid;
  place-items: center;

  @media all and (max-width: 900px) {
    height: fit-content;
    display: block;
    margin: 30px auto;
    max-width: 325px;
  }
}

.maru {
  border-radius: 50% !important;
}

.startButton {
  position: relative;
  margin: 10px;
  width: 150px;
  height: 60px;
  border-radius: 100px;
  background: #ff6060;
  border: none;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.159);

  font-size: 1.4rem;
  font-weight: bold;
  color: whitesmoke;
  padding: 6px;
  position: relative;
}

.sap {
  display: flex;
  justify-content: space-around;
}

.blue {
  background-color: blue;
}

h2 {
  margin: 0;
  text-align: left;
}

.short {
  width: 100px !important;
  border: 4px solid black !important;
  font-size: 25px !important;
  font-weight: bold !important;
}

.settings {
  display: flex;

  @media all and (max-width: 900px) {
    display: block;
  }

  .container {
    flex: 1 1 0;

    .cat {
      height: 50px;
      padding: 3px;
      font-size: 16px;
      font-weight: bold;
      color: rgb(92, 92, 92);
      background-color: rgb(255, 255, 255);
      margin: 3px;
      border-radius: 7px;
      border: 3px solid rgb(199, 199, 199);

      flex-grow: 1;
    }
  }
}

.niceStyle {
  height: 50px;
  min-width: 50px;

  padding: 3px;
  font-size: 16px;
  font-weight: bold;
  color: rgb(92, 92, 92);
  background-color: rgb(255, 255, 255);
  margin: 3px;
  border-radius: 7px;
  border: 3px solid rgb(199, 199, 199);
}

.rootWindow {
  width: 100%;
  height: fit-content;
}

.container {
  padding: 0 20px;
  margin-bottom: 10px;
}

.switched {
  box-sizing: border-box;

  width: 100%;
  height: fit-content;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @media all and (max-width: 900px) {
    padding-bottom: 30px;
  }

  .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .title {
      width: 70px;
      padding-top: 2px;
      margin-right: 8px;
      text-align: center;
      height: 22px;
      line-height: 22px;
    }
  }
}

.line {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  font-weight: bold;
  background-color: rgb(203, 255, 136);
  padding: 10px;

  @media screen and (max-width: 900px) {
    margin-bottom: 100px;
  }
}

.practice {
  width: 40px;
  height: 22px;
  background-size: 30px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(../../../public/img/book.svg);
}

.cat {
  height: 35px;
  padding: 3px;
  font-size: 16px;
  font-weight: bold;
  color: rgb(92, 92, 92);
  background-color: rgb(255, 255, 255);
  margin: 3px;
  border-radius: 7px;
  border: 3px solid rgb(199, 199, 199);

  flex-grow: 1;
}
</style>
