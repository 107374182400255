export const irregular_verbs = [
  [
    {
      meaning: "～を<ruby>始<rt>はじ</rt></ruby>める",
      present: "begin",
      past: "began",
      past_participle: "begun",
      present_participle: "beginning",
    },
    {
      meaning: "～を<ruby>飲<rt>の</rt></ruby>む",
      present: "drink",
      past: "drank",
      past_participle: "drunk",
      present_participle: "drinking",
    },
    {
      meaning:
        "<ruby>鳴<rt>な</rt></ruby>る、～を<ruby>鳴<rt>な</rt></ruby>らす",
      present: "ring",
      past: "rang",
      past_participle: "rung",
      present_participle: "ringing",
    },
    {
      meaning: "<ruby>縮<rt>ちぢ</rt></ruby>む",
      present: "shrink",
      past: "shrank",
      past_participle: "shrunk",
      present_participle: "shrinking",
    },
    {
      meaning:
        "<ruby>歌<rt>うた</rt></ruby>う、～を<ruby>歌<rt>うた</rt></ruby>う",
      present: "sing",
      past: "sang",
      past_participle: "sung",
      present_participle: "singing",
    },
    {
      meaning: "<ruby>沈<rt>しず</rt></ruby>む",
      present: "sink",
      past: "sank",
      past_participle: "sunk",
      present_participle: "sinking",
    },
    {
      meaning: "はねる、( 水が ) わき<ruby>出<rt>で</rt></ruby>る",
      present: "spring",
      past: "sprang",
      past_participle: "sprung",
      present_participle: "springing",
    },
    {
      meaning: "<ruby>悪臭<rt>あくしゅう</rt></ruby>をはなつ",
      present: "stink",
      past: "stank",
      past_participle: "stunk",
      present_participle: "stinking",
    },
    {
      meaning: "<ruby>泳<rt>およ</rt></ruby>ぐ",
      present: "swim",
      past: "swam",
      past_participle: "swum",
      present_participle: "swimming",
    },
  ],
  [
    {
      meaning:
        "<ruby>横<rt>よこ</rt></ruby>になる、<ruby>横<rt>よこ</rt></ruby>たわる",
      present: "lie",
      past: "lay",
      past_participle: "lain",
      present_participle: "lying",
    },
    {
      meaning:
        "～を<ruby>運<rt>はこ</rt></ruby>ぶ、～に<ruby>耐<rt>た</rt></ruby>える",
      present: "bear",
      past: "bore",
      past_participle: "borne",
      present_participle: "bearing",
    },
    {
      meaning: "～を<ruby>引<rt>ひ</rt></ruby>き<ruby>裂<rt>さ</rt></ruby>く",
      present: "tear",
      past: "tore",
      past_participle: "torn",
      present_participle: "tearing",
    },
    {
      meaning: "～を<ruby>身<rt>み</rt></ruby>につけている",
      present: "wear",
      past: "wore",
      past_participle: "worn",
      present_participle: "wearing",
    },
  ],
  [
    {
      meaning: "～を<ruby>噛<rt>か</rt></ruby>む",
      present: "bite",
      past: "bit",
      past_participle: "bitten",
      present_participle: "biting",
    },
    {
      meaning:
        "～を<ruby>壊<rt>こわ</rt></ruby>す、<ruby>壊<rt>こわ</rt></ruby>れる",
      present: "break",
      past: "broke",
      past_participle: "broken",
      present_participle: "breaking",
    },
    {
      meaning: "～を<ruby>選<rt>えら</rt></ruby>ぶ",
      present: "choose",
      past: "chose",
      past_participle: "chosen",
      present_participle: "choosing",
    },
    {
      meaning: "～を<ruby>忘<rt>わす</rt></ruby>れる",
      present: "forget",
      past: "forgot",
      past_participle: "forgotten",
      present_participle: "forgetting",
    },
    {
      meaning:
        "<ruby>凍<rt>こお</rt></ruby>る、<ruby>凍<rt>こお</rt></ruby>らせる",
      present: "freeze",
      past: "froze",
      past_participle: "frozen",
      present_participle: "freezing",
    },
    {
      meaning: "～を<ruby>手<rt>て</rt></ruby>に入れる",
      present: "get",
      past: "got",
      past_participle: "gotten",
      present_participle: "getting",
    },
    {
      meaning: "～を<ruby>隠<rt>かく</rt></ruby>す",
      present: "hide",
      past: "hid",
      past_participle: "hidden",
      present_participle: "hiding",
    },
    {
      meaning:
        "<ruby>話<rt>はな</rt></ruby>す、( <ruby>言語<rt>げんご</rt></ruby> ) を<ruby>話<rt>はな</rt></ruby>す",
      present: "speak",
      past: "spoke",
      past_participle: "spoken",
      present_participle: "speaking",
    },
    {
      meaning: "～を<ruby>盗<rt>ぬす</rt></ruby>む",
      present: "steal",
      past: "stole",
      past_participle: "stolen",
      present_participle: "stealing",
    },
    {
      meaning: "<ruby>目<rt>め</rt></ruby>を<ruby>覚<rt>さ</rt></ruby>ます",
      present: "wake",
      past: "woke",
      past_participle: "woken",
      present_participle: "waking",
    },
    {
      meaning:
        "( ひも ) を<ruby>編<rt>あ</rt></ruby>む、～を<ruby>織<rt>お</rt></ruby>る",
      present: "weave",
      past: "wove",
      past_participle: "woven",
      present_participle: "weaving",
    },
  ],
  [
    {
      meaning: "～である、～になる",
      present: "be was",
      past: "been being",
      past_participle: "be were",
      present_participle: "been being",
    },
    {
      meaning:
        "～が<ruby>見<rt>み</rt></ruby>える、<ruby>見<rt>み</rt></ruby>る",
      present: "see",
      past: "saw",
      past_participle: "seen",
      present_participle: "seeing",
    },
  ],
  [
    {
      meaning:
        "( <ruby>風<rt>かぜ</rt></ruby>が ) <ruby>吹<rt>ふ</rt></ruby>く",
      present: "blow",
      past: "blew",
      past_participle: "blown",
      present_participle: "blowing",
    },
    {
      meaning:
        "～を<ruby>描<rt>えが</rt></ruby>く、～を<ruby>引<rt>ひ</rt></ruby>き<ruby>出<rt></rt></ruby>す",
      present: "draw",
      past: "drew",
      past_participle: "drawn",
      present_participle: "drawing",
    },
    {
      meaning:
        "<ruby>成長<rt>せいちょう</rt></ruby>する、～を<ruby>栽培<rt>さいばい</rt></ruby>する",
      present: "grow",
      past: "grew",
      past_participle: "grown",
      present_participle: "growing",
    },
    {
      meaning: "～を<ruby>知<rt>し</rt></ruby>っている",
      present: "know",
      past: "knew",
      past_participle: "known",
      present_participle: "knowing",
    },
    {
      meaning: "～を<ruby>投<rt>な</rt></ruby>げる",
      present: "throw",
      past: "threw",
      past_participle: "thrown",
      present_participle: "throwing",
    },
  ],
  [
    {
      meaning: "～を<ruby>食<rt>た</rt></ruby>べる",
      present: "eat",
      past: "ate",
      past_participle: "eaten",
      present_participle: "eating",
    },
    {
      meaning: "<ruby>落<rt>お</rt></ruby>ちる",
      present: "fall",
      past: "fell",
      past_participle: "fallen",
      present_participle: "falling",
    },
    {
      meaning: "～を<ruby>与<rt>あた</rt></ruby>える",
      present: "give",
      past: "gave",
      past_participle: "given",
      present_participle: "giving",
    },
    {
      meaning: "～を<ruby>間違<rt>まちが</rt></ruby>える",
      present: "mistake",
      past: "mistook",
      past_participle: "mistaken",
      present_participle: "mistaking",
    },
    {
      meaning:
        "～を<ruby>振<rt>ふ</rt></ruby>る、<ruby>揺<rt>ゆ</rt></ruby>れる",
      present: "shake",
      past: "shook",
      past_participle: "shaken",
      present_participle: "shaking",
    },
    {
      meaning:
        "～を<ruby>手<rt>て</rt></ruby>に<ruby>取<rt>と</rt></ruby>る、～を<ruby>持<rt>も</rt></ruby>って<ruby>行<rt>い</rt></ruby>く",
      present: "take",
      past: "took",
      past_participle: "taken",
      present_participle: "taking",
    },
  ],
  [
    {
      meaning: "～をする",
      present: "do",
      past: "did",
      past_participle: "done",
      present_participle: "doing",
    },
    {
      meaning: "<ruby>飛<rt>と</rt></ruby>ぶ",
      present: "fly",
      past: "flew",
      past_participle: "flown",
      present_participle: "flying",
    },
    {
      meaning: "<ruby>行<rt>い</rt></ruby>く",
      present: "go",
      past: "went",
      past_participle: "gone",
      present_participle: "going",
    },
    {
      meaning: "～を<ruby>見<rt>み</rt></ruby>せる",
      present: "show",
      past: "showed",
      past_participle: "shown",
      present_participle: "showing",
    },
  ],
  [
    {
      meaning: "～を<ruby>運転<rt>うんてん</rt></ruby>する",
      present: "drive",
      past: "drove",
      past_participle: "driven",
      present_participle: "driving",
    },
    {
      meaning: "<ruby>乗<rt>の</rt></ruby>る",
      present: "ride",
      past: "rode",
      past_participle: "ridden",
      present_participle: "riding",
    },
    {
      meaning: "<ruby>上<rt>あ</rt></ruby>がる",
      present: "rise",
      past: "rose",
      past_participle: "risen",
      present_participle: "rising",
    },
    {
      meaning:
        "<ruby>大<rt>おお</rt></ruby>またで<ruby>歩<rt>ある</rt></ruby>く",
      present: "stride",
      past: "strode",
      past_participle: "stridden",
      present_participle: "striding",
    },
    {
      meaning: "～を<ruby>書<rt>か</rt></ruby>く",
      present: "write",
      past: "wrote",
      past_participle: "written",
      present_participle: "writing",
    },
    {
      meaning: "はれる、ふくらむ",
      present: "swell",
      past: "swelled",
      past_participle: "swollen",
      present_participle: "swelling",
    },
  ],
  [
    {
      meaning: "～になる",
      present: "become",
      past: "became",
      past_participle: "become",
      present_participle: "becoming",
    },
    {
      meaning: "<ruby>来<rt>く</rt></ruby>る",
      present: "come",
      past: "came",
      past_participle: "come",
      present_participle: "coming",
    },
    {
      meaning: "<ruby>走<rt>はし</rt></ruby>る",
      present: "run",
      past: "ran",
      past_participle: "run",
      present_participle: "running",
    },
  ],
  [
    {
      meaning:
        "～を<ruby>続<rt>つづ</rt></ruby>けて<ruby>打<rt>う</rt></ruby>つ、～を<ruby>打<rt>う</rt></ruby>ち<ruby>負<rt>ま</rt></ruby>かす",
      present: "beat",
      past: "beat",
      past_participle: "beat",
      present_participle: "beating",
    },
    {
      meaning:
        "( <ruby>金<rt>かね</rt></ruby> ) を<ruby>賭<rt>か</rt></ruby>ける",
      present: "bet",
      past: "bet",
      past_participle: "bet",
      present_participle: "betting",
    },
    {
      meaning: "<ruby>爆発<rt>ばくはつ</rt></ruby>する",
      present: "burst",
      past: "burst",
      past_participle: "burst",
      present_participle: "bursting",
    },
    {
      meaning: "～をねらって<ruby>投<rt>な</rt></ruby>げる",
      present: "cast",
      past: "cast",
      past_participle: "cast",
      present_participle: "casting",
    },
    {
      meaning:
        "( <ruby>費用<rt>ひよう</rt></ruby> ) を<ruby>必要<rt>ひつよう</rt></ruby>とする",
      present: "cost",
      past: "cost",
      past_participle: "cost",
      present_participle: "costing",
    },
    {
      meaning: "～を<ruby>切<rt>き</rt></ruby>る",
      present: "cut",
      past: "cut",
      past_participle: "cut",
      present_participle: "cutting",
    },
    {
      meaning: "～に<ruby>合<rt>あ</rt></ruby>う",
      present: "fit",
      past: "fit",
      past_participle: "fit",
      present_participle: "fitting",
    },
    {
      meaning: "～をたたく",
      present: "hit",
      past: "hit",
      past_participle: "hit",
      present_participle: "hitting",
    },
    {
      meaning: "～を<ruby>傷<rt>きず</rt></ruby>つける",
      present: "hurt",
      past: "hurt",
      past_participle: "hurt",
      present_participle: "hurting",
    },
    {
      meaning: "( <ruby>人<rt>ひと</rt></ruby> ) に～させる",
      present: "let",
      past: "let",
      past_participle: "let",
      present_participle: "letting",
    },
    {
      meaning: "～を<ruby>置<rt>お</rt></ruby>く",
      present: "put",
      past: "put",
      past_participle: "put",
      present_participle: "putting",
    },
    {
      meaning:
        "( <ruby>仕事<rt>しごと</rt></ruby>・<ruby>習慣<rt>しゅうかん</rt></ruby> ) をやめる",
      present: "quit",
      past: "quit",
      past_participle: "quit",
      present_participle: "quitting",
    },
    {
      meaning:
        "～から<ruby>取<rt>と</rt></ruby>り<ruby>除<rt>のぞ</rt></ruby>く",
      present: "rid",
      past: "rid",
      past_participle: "rid",
      present_participle: "ridding",
    },
    {
      meaning: "～を<ruby>配置<rt>はいち</rt></ruby>する",
      present: "set",
      past: "set",
      past_participle: "set",
      present_participle: "setting",
    },
    {
      meaning:
        "( <ruby>血<rt>ち</rt></ruby>・<ruby>涙<rt>なみだ</rt></ruby> ) を<ruby>流<rt>なが</rt></ruby>す",
      present: "shed",
      past: "shed",
      past_participle: "shed",
      present_participle: "shedding",
    },
    {
      meaning: "～を<ruby>閉<rt>し</rt></ruby>める",
      present: "shut",
      past: "shut",
      past_participle: "shut",
      present_participle: "shutting",
    },
    {
      meaning:
        "～を<ruby>分割<rt>ぶんかつ</rt></ruby>する、<ruby>裂<rt>さ</rt></ruby>ける",
      present: "split",
      past: "split",
      past_participle: "split",
      present_participle: "splitting",
    },
    {
      meaning:
        "～を<ruby>広<rt>ひろ</rt></ruby>げる、<ruby>広<rt>ひろ</rt></ruby>がる",
      present: "spread",
      past: "spread",
      past_participle: "spread",
      present_participle: "spreading",
    },
  ],
  [
    {
      meaning: "～を<ruby>持<rt>も</rt></ruby>って<ruby>来<rt>く</rt></ruby>る",
      present: "bring",
      past: "brought",
      past_participle: "brought",
      present_participle: "bringing",
    },
    {
      meaning: "～を<ruby>買<rt>か</rt></ruby>う",
      present: "buy",
      past: "bought",
      past_participle: "bought",
      present_participle: "buying",
    },
    {
      meaning: "～を<ruby>捕<rt>つか</rt></ruby>まえる",
      present: "catch",
      past: "caught",
      past_participle: "caught",
      present_participle: "catching",
    },
    {
      meaning: "<ruby>戦<rt>たたか</rt></ruby>う",
      present: "fight",
      past: "fought",
      past_participle: "fought",
      present_participle: "fighting",
    },
    {
      meaning:
        "～を<ruby>捜<rt>さが</rt></ruby>し<ruby>求<rt>もと</rt></ruby>める",
      present: "seek",
      past: "sought",
      past_participle: "sought",
      present_participle: "seeking",
    },
    {
      meaning: "～を<ruby>教<rt>おし</rt></ruby>える",
      present: "teach",
      past: "taught",
      past_participle: "taught",
      present_participle: "teaching",
    },
    {
      meaning: "～と<ruby>思<rt>おも</rt></ruby>う",
      present: "think",
      past: "thought",
      past_participle: "thought",
      present_participle: "thinking",
    },
  ],
  [
    {
      meaning: "～を<ruby>分配<rt>ぶんぱい</rt></ruby>する",
      present: "deal",
      past: "dealt",
      past_participle: "dealt",
      present_participle: "dealing",
    },
    {
      meaning: "～を<ruby>感<rt>かん</rt></ruby>じる",
      present: "feel",
      past: "felt",
      past_participle: "felt",
      present_participle: "feeling",
    },
    {
      meaning:
        "～を<ruby>持<rt>も</rt></ruby>ち<ruby>続<rt>つづ</rt></ruby>ける、～を ( ある<ruby>状態<rt>じょうたい</rt></ruby> ) にしておく",
      present: "keep",
      past: "kept",
      past_participle: "kept",
      present_participle: "keeping",
    },
    {
      meaning:
        "～を<ruby>去<rt>さ</rt></ruby>る、～を<ruby>残<rt>のこ</rt></ruby>す",
      present: "leave",
      past: "left",
      past_participle: "left",
      present_participle: "leaving",
    },
    {
      meaning: "～を<ruby>意味<rt>いみ</rt></ruby>する",
      present: "mean",
      past: "meant",
      past_participle: "meant",
      present_participle: "meaning",
    },
    {
      meaning: "～に<ruby>会<rt>あ</rt></ruby>う",
      present: "meet",
      past: "met",
      past_participle: "met",
      present_participle: "meeting",
    },
    {
      meaning: "<ruby>眠<rt>ねむ</rt></ruby>る",
      present: "sleep",
      past: "slept",
      past_participle: "slept",
      present_participle: "sleeping",
    },
    {
      meaning: "～をほうきではく",
      present: "sweep",
      past: "swept",
      past_participle: "swept",
      present_participle: "sweeping",
    },
  ],
  [
    {
      meaning: "<ruby>出血<rt>しゅっけつ</rt></ruby>する",
      present: "bleed",
      past: "bled",
      past_participle: "bled",
      present_participle: "bleeding",
    },
    {
      meaning:
        "～に<ruby>食<rt>た</rt></ruby>べ<ruby>物<rt>もの</rt></ruby>を<ruby>与<rt>あた</rt></ruby>える",
      present: "feed",
      past: "fed",
      past_participle: "fed",
      present_participle: "feeding",
    },
    {
      meaning: "<ruby>急<rt>いそ</rt></ruby>いで<ruby>逃<rt>に</rt></ruby>げる",
      present: "flee",
      past: "fled",
      past_participle: "fled",
      present_participle: "fleeing",
    },
    {
      meaning: "～を<ruby>導<rt>みちび</rt></ruby>く",
      present: "lead",
      past: "led",
      past_participle: "led",
      present_participle: "leading",
    },
    {
      meaning: "～を<ruby>読<rt>よ</rt></ruby>む",
      present: "read",
      past: "read",
      past_participle: "read",
      present_participle: "reading",
    },
  ],
  [
    {
      meaning: "～を<ruby>曲<rt>ま</rt></ruby>げる",
      present: "bend",
      past: "bent",
      past_participle: "bent",
      present_participle: "bending",
    },
    {
      meaning: "～を<ruby>建<rt>た</rt></ruby>てる",
      present: "build",
      past: "built",
      past_participle: "built",
      present_participle: "building",
    },
    {
      meaning:
        "( <ruby>人<rt>ひと</rt></ruby> ) に( <ruby>物<rt>もの</rt></ruby>・<ruby>金<rt>かね</rt></ruby>) を<ruby>貸<rt>か</rt></ruby>す",
      present: "lend",
      past: "lent",
      past_participle: "lent",
      present_participle: "lending",
    },
    {
      meaning: "～を<ruby>送<rt>おく</rt></ruby>る",
      present: "send",
      past: "sent",
      past_participle: "sent",
      present_participle: "sending",
    },
    {
      meaning:
        "( <ruby>金<rt>かね</rt></ruby> ) を使う、( <ruby>時間<rt>じかん</rt></ruby> ) を<ruby>費<rt>つい</rt></ruby>やす",
      present: "spend",
      past: "spent",
      past_participle: "spent",
      present_participle: "spending",
    },
  ],
  [
    {
      meaning: "～をしばる",
      present: "bind",
      past: "bound",
      past_participle: "bound",
      present_participle: "binding",
    },
    {
      meaning: "～を<ruby>見<rt>み</rt></ruby>つける",
      present: "find",
      past: "found",
      past_participle: "found",
      present_participle: "finding",
    },
    {
      meaning:
        "～を<ruby>研<rt>と</rt></ruby>ぐ、( <ruby>穀物<rt>こくもつ</rt></ruby> ) をひく",
      present: "grind",
      past: "ground",
      past_participle: "ground",
      present_participle: "grinding",
    },
    {
      meaning:
        "( <ruby>川<rt>かわ</rt></ruby>・<ruby>道<rt>みち</rt></ruby>が ) <ruby>曲<rt>ま</rt></ruby>がる、～を<ruby>巻<rt>ま</rt></ruby>く",
      present: "wind",
      past: "wound",
      past_participle: "wound",
      present_participle: "winding",
    },
  ],
  [
    {
      meaning: "～を<ruby>掘<rt>ほ</rt></ruby>る",
      present: "dig",
      past: "dug",
      past_participle: "dug",
      present_participle: "digging",
    },
    {
      meaning:
        "( コマ ) を<ruby>回<rt>まわ</rt></ruby>す、<ruby>回転<rt>かいてん</rt></ruby>する",
      present: "spin",
      past: "spun",
      past_participle: "spun",
      present_participle: "spinning",
    },
    {
      meaning: "～を<ruby>突<rt>つ</rt></ruby>き<ruby>刺<rt>さ</rt></ruby>す",
      present: "stick",
      past: "stuck",
      past_participle: "stuck",
      present_participle: "sticking",
    },
    {
      meaning:
        "～を<ruby>針<rt>はり</rt></ruby>・トゲで<ruby>刺<rt>さ</rt></ruby>す",
      present: "sting",
      past: "stung",
      past_participle: "stung",
      present_participle: "stinging",
    },
    {
      meaning: "～を<ruby>揺<rt>ゆ</rt></ruby>らす",
      present: "swing",
      past: "swung",
      past_participle: "swung",
      present_participle: "swinging",
    },
    {
      meaning:
        "( <ruby>戦<rt>いくさ</rt></ruby>・<ruby>競争<rt>きょうそう</rt></ruby> ) に<ruby>勝<rt>か</rt></ruby>つ",
      present: "win",
      past: "won",
      past_participle: "won",
      present_participle: "winning",
    },
  ],
  [
    {
      meaning: "～に<ruby>点火<rt>てんか</rt></ruby>する",
      present: "light",
      past: "lit",
      past_participle: "lit",
      present_participle: "lighting",
    },
    {
      meaning: "なめらかに<ruby>滑<rt>すべ</rt></ruby>る",
      present: "slide",
      past: "slid",
      past_participle: "slid",
      present_participle: "sliding",
    },
    {
      meaning: "～を<ruby>売<rt>う</rt></ruby>る",
      present: "sell",
      past: "sold",
      past_participle: "sold",
      present_participle: "selling",
    },
    {
      meaning: "～を<ruby>伝<rt>つた</rt></ruby>える",
      present: "tell",
      past: "told",
      past_participle: "told",
      present_participle: "telling",
    },
    {
      meaning: "<ruby>立<rt>た</rt></ruby>つ",
      present: "stand",
      past: "stood",
      past_participle: "stood",
      present_participle: "standing",
    },
    {
      meaning: "～を<ruby>理解<rt>りかい</rt></ruby>する",
      present: "understand",
      past: "understood",
      past_participle: "understood",
      present_participle: "understanding",
    },
    {
      meaning: "～をつるす",
      present: "hang",
      past: "hung",
      past_participle: "hung",
      present_participle: "hanging",
    },
    {
      meaning: "～が<ruby>聞<rt>き</rt></ruby>こえる",
      present: "hear",
      past: "heard",
      past_participle: "heard",
      present_participle: "hearing",
    },
    {
      meaning: "～をしっかり<ruby>握<rt>にぎ</rt></ruby>る",
      present: "hold",
      past: "held",
      past_participle: "held",
      present_participle: "holding",
    },
    {
      meaning: "～を<ruby>失<rt>うしな</rt></ruby>う",
      present: "lose",
      past: "lost",
      past_participle: "lost",
      present_participle: "losing",
    },
    {
      meaning: "～を<ruby>言<rt>い</rt></ruby>う",
      present: "say",
      past: "said",
      past_participle: "said",
      present_participle: "saying",
    },
    {
      meaning: "<ruby>輝<rt>かがや</rt></ruby>く",
      present: "shine",
      past: "shone",
      past_participle: "shone",
      present_participle: "shining",
    },
    {
      meaning: "～を<ruby>撃<rt>う</rt></ruby>つ",
      present: "shoot",
      past: "shot",
      past_participle: "shot",
      present_participle: "shooting",
    },
    {
      meaning: "<ruby>座<rt>すわ</rt></ruby>る",
      present: "sit",
      past: "sat",
      past_participle: "sat",
      present_participle: "sitting",
    },
    {
      meaning: "～を<ruby>打<rt>う</rt></ruby>つ",
      present: "strike",
      past: "struck",
      past_participle: "struck",
      present_participle: "striking",
    },
  ],
  [
    {
      meaning: "～を<ruby>持<rt>も</rt></ruby>っている",
      present: "have",
      past: "had",
      past_participle: "had",
      present_participle: "having",
    },
    {
      meaning:
        "～を<ruby>横<rt>よこ</rt></ruby>たえる、～を<ruby>置<rt>お</rt></ruby>く",
      present: "lay",
      past: "laid",
      past_participle: "laid",
      present_participle: "laying",
    },
    {
      meaning: "～を<ruby>作<rt>つく</rt></ruby>る",
      present: "make",
      past: "made",
      past_participle: "made",
      present_participle: "making",
    },
    {
      meaning:
        "(<ruby>金<rt>かね</rt></ruby>) を<ruby>支払<rt>しはら</rt></ruby>う",
      present: "pay",
      past: "paid",
      past_participle: "paid",
      present_participle: "paying",
    },
  ],
  [
    {
      meaning:
        "<ruby>遊<rt>あそ</rt></ruby>ぶ、（<ruby>楽器<rt>がっき</rt></ruby>）を<ruby>演奏<rt>えんそう</rt></ruby>する",
      present: "play",
      past: "played",
      past_participle: "played",
      present_participle: "playing",
    },
    {
      meaning: "<ruby>住<rt>す</rt></ruby>む",
      present: "live",
      past: "lived",
      past_participle: "lived",
      present_participle: "living",
    },
    {
      meaning:
        "～を<ruby>学<rt>まな</rt></ruby>ぶ、<ruby>勉強<rt>べんきょう</rt></ruby>する",
      present: "study",
      past: "studied",
      past_participle: "studied",
      present_participle: "studying",
    },
    {
      meaning: "～をやめる、<ruby>止<rt>と</rt></ruby>まる",
      present: "stop",
      past: "stopped",
      past_participle: "stopped",
      present_participle: "stopping",
    },
    {
      meaning: "～を<ruby>好<rt>この</rt></ruby>む",
      present: "prefer",
      past: "preferred",
      past_participle: "preferred",
      present_participle: "preferring",
    },
    {
      meaning: "あわてふためく",
      present: "panic",
      past: "panicked",
      past_participle: "panicked",
      present_participle: "panicking",
    },
  ],
];

export const levelTable = [
  0, 0, 0, 0, 0, 0, 0, 0, 1, 2, 3, 3, 3, 3, 3, 3, 3, 3, 4,
];

export const lookUpTable = [
  "ABC型１",
  "ABC型２",
  "ABC型３",
  "ABC型４",
  "ABC型５",
  "ABC型６",
  "ABC型７",
  "ABC型８",
  "ABA型",
  "AAA型",
  "ABB型１",
  "ABB型２",
  "ABB型３",
  "ABB型４",
  "ABB型５",
  "ABB型６",
  "ABB型７",
  "ABB型８",
  "規則動詞",
];
