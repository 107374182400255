<template>
  <div id="top-header">
    <a href="https://smile-english.online/bookshelf">
      <div class="mododru">
        <div class="icon" />
        <div class="cap">ホーム</div>
      </div>
    </a>
    <img src="@/assets/img/logos/DreaMagicLogoB.png" alt="" />
  </div>
</template>

<script>
export default {
  name: "Header",
  mounted() {
    // クッキー 'isActive' の値を取得
    const isActiveCookie = this.getCookie('isActive');
    const referrer = document.referrer;
    console.log(referrer);
    // クッキー 'isActive' が存在しない場合
    if (!isActiveCookie) {
      // リファラー情報を取得
      const referrer = document.referrer;

      // 元のサイトが 'https://google.co.jp' の場合
      if (referrer.includes('https://smile-english.online')) {
        // クッキー 'isActive' を設定（有効期限: 1日）
        this.setCookie('isActive', 'true', 1);
      } else {
        // 元のサイトが 'https://google.co.jp' 以外の場合はリダイレクト
        window.location.href = 'https://smile-english.online/bookshelf';
      }
    }
  },
  methods: {
    // クッキーを取得するメソッド
    getCookie(name) {
      const cookieName = name + "=";
      const decodedCookie = decodeURIComponent(document.cookie);
      const cookieArray = decodedCookie.split(';');
      for (let i = 0; i < cookieArray.length; i++) {
        let cookie = cookieArray[i];
        while (cookie.charAt(0) == ' ') {
          cookie = cookie.substring(1);
        }
        if (cookie.indexOf(cookieName) == 0) {
          return cookie.substring(cookieName.length, cookie.length);
        }
      }
      return null;
    },
    // クッキーを設定するメソッド
    setCookie(name, value, days) {
      const date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      const expires = "expires=" + date.toUTCString();
      document.cookie = name + "=" + value + ";" + expires + ";path=/";
    }
  }
};
</script>

<style lang="scss">
#top-header {
  position: fixed;
  z-index: 10;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 15px -5px #b6b6b6;
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;

  img {
    margin: 12px 0 18px;
    object-fit: cover;
    height: 40px;
    width: auto;
  }
}

.icon {
  width: 20px;
  height: 18px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../assets/img/home.svg");
}

.cap {
  font-weight: 800;
}

.mododru {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 100px;
  border-radius: 5px;
  margin: 10px 0 0 10px;
  &:hover{
    background-color: rgb(236, 236, 236);
  }
}

:visited{
  color: black;
}
</style>
