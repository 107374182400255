import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    total: 0,
    problems:[],
    currentNum:0,
    score: 0,
  },
  mutations: {
    setProblems (state, payload) {
      state.problems = payload;
    },
    setScore (state, payload) {
      state.score = payload;
    },
    setCurrentNum(state, payload){
      state.currentNum = payload;
    },
    setTotal(state, payload){
      state.total = payload;
    },
  },
});
