<template>
  <div id="mainWindow">
    <mainCard></mainCard>
  </div>
</template>

<script>
import mainCard from "./card/maincard.vue";
export default {
  name: "game",
  data() {
    return {};
  },
  components: {
    mainCard,
  },
  methods: {},
};
</script>

<style lang="scss">
#mainWindow {
  width: 100%;
  // height: calc(100% - 70px);
  height: calc(100% - 70px);
  position: relative;
  margin-top: 70px;
  background-color: azure;
  background-position: center center;
  background-size: cover;
  display: grid !important;
  place-items: center;
  @media all and (max-width: 900px) {
    display: block;
  }
}

body,
html {
  height: 100%;
}
</style>
