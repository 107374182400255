<template>
  <div class="mainCanvas">
    <audio id="testAudio" :src="blobUrl"></audio>

    <div class="group">
      <div class="waku">
        <button class="closeBUtton" @click="close">戻る</button>
      </div>
      <div class="status">
        <div class="item">第{{ cnum }}問</div>
        <div class="item">{{ topText }}</div>
        <div class="item">{{ score }} / {{ pbLen }}</div>
      </div>
      <div class="outline">
        <div class="judgeEffect" :class="effect"></div>

        <div class="userInput">
          <div v-if="inNext" class="buttons">
            <Button class="player-otehon" @click="playSound()"
              >お手本を聞く</Button
            >
          </div>
          <div class="problem"><div v-html="currentProblem.meaning"></div></div>
          <div class="line">
            {{ results }}
          </div>
        </div>
      </div>
      <Transition mode="out-in">
        <div v-if="inNext && !isSeikai" class="outline">
          <div class="line">
            AIは次のように認識しました<br />

            <span style="font-weight: bold">{{ recg }}</span>
          </div>
        </div>
      </Transition>
      <Transition mode="out-in">
        <Button
          v-if="!inNext"
          class="button"
          :class="listningCss"
          @click="startRecgonition"
        >
          <div v-if="listning">
            <p>{{ animeObject.timeLeft }}</p>
          </div>
          <div v-if="processing">
            <div class="spinner-box">
              <div class="circle-border">
                <div class="circle-core"></div>
              </div>
            </div>
          </div>
        </Button>

        <div v-else class="buttons">
          <div
            class="player"
            onclick="document.getElementById('testAudio').play()"
          >
            録音を聞く
          </div>

          <Button v-if="inNext" class="nextButton" @click="retry"
            >再挑戦</Button
          >
          <Button
            v-if="inNext && cnum == pbLen"
            class="nextButton"
            @click="close"
            >戻る</Button
          >
          <Button
            v-if="inNext && cnum != pbLen"
            class="nextButton"
            @click="next"
            >次へ</Button
          >
        </div>
      </Transition>

      <!-- <button class="audioButton"></button> -->
    </div>
  </div>
</template>

<script>
import anime from "animejs";
import RecorderService from "@/shared/RecorderService";
export default {
  name: "gameWindow",
  props: {},
  asyncData() {
    return {};
  },
  data() {
    return {
      problem: "Scripted",
      prompt: "DEMO v1.11.9",
      results: "マイクを押してから発音しよう！",
      listning: false,
      recg: "",
      processing: false,
      animeObject: { timeLeft: "" },
      timerID: -1,
      codec: ".wav",
      waiting: false,
      recordings: [],
      targe_prompt: "",
      blobUrl: "",
      topText: "",
      currentProblem: {
        meaning: "-",
        present: "-",
        past: "-",
        past_participle: "-",
        present_participle: "-",
      },
      effect: "normal",
      inNext: false,
      isSeikai: false,
      canAddScore: true,
    };
  },
  computed: {
    prom() {
      return this.prompt;
    },

    listningCss() {
      if (this.waiting) {
        return "stop";
      }
      return this.listning ? "listning" : "";
    },

    score() {
      return this.$store.state.score;
    },
    pb() {
      return this.$store.state.problems;
    },

    pbLen() {
      return this.$store.state.total;
    },

    cnum() {
      return this.$store.state.currentNum;
    },
  },
  mounted() {
    this.next();
  },
  created() {
    if (this.pb.length == 0) {
      this.$router.push(`/`);
    }
    this.recorderSrvc = new RecorderService();
    // Override to force all browsers to use manual encoding
    this.recorderSrvc.config.usingMediaRecorder = false;
    this.hasMediaRecorder = this.recorderSrvc.config.usingMediaRecorder;
    this.recorderSrvc.em.addEventListener("recording", (evt) =>
      this.onNewRecording(evt)
    );
  },
  methods: {
    next() {
      this.inNext = false;
      const next = this.pb[this.cnum];
      this.currentProblem = next;
      this.$store.commit("setCurrentNum", this.cnum + 1);
      this.topText = "-";
      this.canAddScore = true;
      this.results = "マイクを押してから発音しよう！";
    },

    playSound() {
      const music = new Audio(this.generateAudioURL());
      music.play();
    },

    close() {
      this.$router.push("/");
    },

    retry() {
      this.inNext = false;
    },

    generateAudioURL() {
      const p = this.pb[this.cnum - 1];

      return `/sound/${p.present}_e.wav`;
    },

    generateAnswer() {
      const p = this.currentProblem;
      return `${p.present} ${p.past} ${p.past_participle} ${p.present_participle}`;
    },

    textFix(text) {
      let tmp = text.toLowerCase().replaceAll(/[^a-z ]/g, "");
      return tmp === "" ? "-" : tmp;
    },

    startRecgonition() {
      if (this.processing) return;
      if (this.listning) {
        this.stopRecording();
        return;
      }

      this.topText = "準備中";
      this.listning = true;

      this.waiting = true;
      this.animeObject = { timeLeft: "" };
      this.recorderSrvc.config.manualEncoderId = "wav";
      this.recorderSrvc
        .startRecording()
        .then(() => {
          this.waiting = false;
          this.recordingInProgress = true;
          this.topText = "認識中";
          anime({
            targets: this.animeObject,
            timeLeft: [5, 0],
            round: 1,
            duration: 5000,
            delay: 500,
            easing: "linear",
          });
          this.timerID = setTimeout(() => {
            if (!this.listning) return;
            this.stopRecording();
          }, 5100);
        })
        .catch((error) => {
          console.error("Exception while start recording: " + error);
          alert("Exception while start recording: " + error.message);
        });
    },
    onNewRecording(evt) {
      this.topText = "解析中";
      let formData = new FormData();
      formData.append("file", evt.detail.recording.blob, "test.wav");
      this.blobUrl = evt.detail.recording.blobUrl;
      formData.append("model", "whisper-1");
      // formData.append(
      //   "prompt",
      //   `This transcription should result in the following group of irregular verbs ${this.generateAnswer()}`
      // );
      formData.append(
        "prompt",
        `This transcription could result in the following group of irregular verb conjugations such as ${this.generateAnswer()}  `
      );

      fetch("https://api.openai.com/v1/audio/transcriptions", {
        method: "POST",
        headers: {
          Authorization:
            "Bearer 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",
        },
        body: formData,
      })
        .then((response) => {
          return response.text();
        })
        .then((text) => {
          const resultJson = JSON.parse(text);
          this.recg = this.textFix(resultJson.text);
          this.results = this.generateAnswer();
          this.processing = false;

          //ここから判定処理
          this.inNext = true;
          if (this.recg == this.generateAnswer()) {
            this.isSeikai = true;
            this.effect = "seikai";
            this.topText = "-";
            if (this.canAddScore) {
              this.$store.commit("setScore", this.score + 1);
            }
            setTimeout(() => {
              this.effect = "seikai normal";
            }, 1000);
          } else {
            this.isSeikai = false;
            this.effect = "matigai";
            setTimeout(() => {
              this.effect = "matigai normal";
            }, 1000);
            this.canAddScore = false;
            this.topText = "-";
            this.playSound();
          }
        })
        .catch((e) => {
          console.log(e);
          this.prompt = "Something went wrong";
          this.processing = false;
        });
    },

    stopRecording() {
      this.prompt = "PROCESSING";
      this.processing = true;
      this.listning = false;
      this.recordingInProgress = false;
      this.recorderSrvc.stopRecording();
      clearTimeout(this.timerID);
    },
    arrayBufferToBase64(buffer) {
      let binary = "";
      let bytes = new Float32Array(buffer);
      let len = bytes.byteLength;
      for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return window.btoa(binary);
    },
    getExtension(audioType) {
      let extension = "wav";
      const matches = audioType.match(/audio\/([^;]+)/);

      if (matches) {
        extension = matches[1];
      }

      return "." + extension;
    },
  },
};
</script>

<style scoped lang="scss">
.answer {
  text-decoration: underline;
}

.closeBUtton {
  position: relative;
  margin: 10px 0;
  width: 70px;
  // padding-top: 6px;
  height: 28px;
  border-radius: 100px;
  background: #60cdff;
  border: none;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.159);

  font-size: 1rem;
  line-height: 1rem;

  font-weight: bold;
  color: whitesmoke;
  padding: 6px;
  position: relative;
}

.status {
  background-color: #fbab7e;
  background-image: linear-gradient(62deg, #fbab7e 0%, #f7ce68 100%);

  border: 2px solid rgb(241, 127, 127);
  height: fit-content;
  font-size: 1.4rem;
  font-weight: bold;
  color: rgb(3, 3, 3);
  padding: 10px;
  border-radius: 100px;
}

.status {
  max-width: 700px;
  margin: 0 auto 0px;
  display: flex;
  color: #000000;
  font-weight: bold;

  .item {
    width: 33%;
    padding-top: 3px;
  }
}

.player {
  font-weight: normal;
  background-color: rgb(53, 53, 53);
  border-radius: 100px;
  color: white;
  font-size: 1rem;
  padding: 4px;
  max-width: 700px;
  margin: auto;
}

.player-otehon {
  font-weight: normal;
  background-color: rgb(14, 189, 116);
  border-radius: 100px;

  color: white;
  font-size: 1rem;
  padding: 5px 20px 2px;
  max-width: 700px;
  margin: auto;
}

.correct {
  color: #37a654;
}

.almost_correct {
  color: #e6cf3c;
}

.incorrect {
  color: #b54435;
}

.input {
  margin: 15px 0 10px;
  background: transparent;
  // border-radius: 5px;
  padding-left: 13px;
  width: 400px;
  height: 2rem;
  border: none;
  outline: none;
  border-bottom: 1px solid black;
}

.audioButton {
  position: relative;
  margin: 10px;
  width: 200px;
  height: 100px;
  border-radius: 50px;
  background: #ff6060;
  border: none;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.159);
  transform: translateY(0px);
  transition: transform 0.2s;
  font-size: 20px;
  color: whitesmoke;
  padding: 6px;
  position: relative;
}

.button {
  position: relative;
  outline: none;
  margin: 10px;
  width: 94px;
  height: 94px;
  border-radius: 50%;
  background: #ff6060;
  border: none;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.159);
  background-image: url(../../assets/img/icon/mic.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  transform: translateY(0px);
  transition: transform 0.2s ease background 0.2s ease;
  font-size: 50px;
  color: whitesmoke;
  padding: 6px;
  position: relative;

  p {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0;

    position: absolute;
  }
}

.waku {
  margin: auto;
  max-width: 700px;
  text-align: left;
}

.nextButton {
  position: relative;
  margin: 10px;
  width: 150px;
  height: 60px;
  border-radius: 100px;
  background: #ff6060;
  border: none;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.159);

  font-size: 1.4rem;
  font-weight: bold;
  color: whitesmoke;
  padding: 6px;
  position: relative;

  @media all and (max-width: 900px) {
    font-size: 1rem;
    width: 200px;
    height: 50px;
    width: 120px;
    padding: 0px;
  }
}

.button-play {
}

.button:hover {
  background: #f64e4e;
  background-image: url(../../assets/img/icon/mic.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  outline: none;
}

.button:active {
  background: #f64e4e;
  background-image: url(../../assets/img/icon/mic.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  transform: translateY(3px);
  outline: none;
}

.listning {
  background-image: none !important;
}

.processing {
  background-image: none !important;
}

.listning:after {
  pointer-events: none;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: none;
  position: absolute;
  z-index: 0;
  border-radius: 50%;
  animation-name: spred;
  animation-duration: 1s;
  animation-iteration-count: infinite;

  border: 1px solid #f64e4e;
  animation-timing-function: ease;
}

.spinner-box {
  width: 8px;
  height: 88px;
  display: grid;
  background-color: transparent;
  pointer-events: none;
}

.circle-border {
  pointer-events: none;
  width: 82px;
  height: 82px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(63, 249, 220);
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0.1) 33%,
    rgb(255, 255, 255) 100%
  );
  animation: spin 0.8s linear 0s infinite;
}

.circle-core {
  width: 100%;
  height: 100%;
  background-color: #ff6060;
  border-radius: 50%;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

@keyframes spred {
  0% {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border: 5px solid #f64e4e;
  }

  100% {
    top: -20px;
    bottom: -20px;
    left: -20px;
    right: -20px;
    border: 1px solid #f64e4e00;
  }
}

.listning {
}

.mainCanvas {
  width: 900px;

  @media all and (max-width: 900px) {
    width: 90%;
  }
}

.judgeEffect {
  position: absolute;

  width: 100%;
  height: 50px;

  pointer-events: none;

  background-repeat: no-repeat;
  background-position: center;

  z-index: 10;

  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  opacity: 0;
}

.seikai {
  background-image: url(../../assets/img/icon/Asset\ 1.svg);
  transform: rotateY(180deg) scale(1.5);
  opacity: 1;
}

.matigai {
  background-image: url(../../assets/img/icon/Asset\ 2.svg);
  transform: rotateY(180deg) scale(1.5);
  opacity: 1;
}

.normal {
  transform: rotateY(0deg) scale(1);
  opacity: 0;
}

.outline {
  margin: 10px auto;
  height: fit-content;

  transition: height 1s ease;
  width: 100%;
  max-width: 700px;
  padding: 5px;
  border-radius: 15px;
  background: rgb(255, 196, 47);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.159);

  position: relative;

  .problem {
    max-width: 700px;
    height: 2.5rem;
    font-size: 1.8rem;
    font-weight: bold;
    // margin: 40px auto;
    padding: 10px 0;
    width: 100%;
    display: grid;
    place-items: center center;

    @media all and (max-width: 900px) {
      width: calc(100%);
      font-size: 1.2rem;
      height: 50px;
    }
  }

  .userInput {
    background: rgb(255, 255, 255);
    width: 100%;
    height: 200px;
    font-size: 2rem;
    padding: 10px;
    display: grid;
    place-items: center;
    border-radius: 15px;
    background-image: repeating-linear-gradient(
      #ffffff 0px,
      #ffffff 40px,
      #d8d6c7 41px
    );

    @media all and (max-width: 900px) {
      width: 100%;
      font-size: 1rem;
      height: 150px;
      background-image: repeating-linear-gradient(
        #ffffff 0px,
        #ffffff 20px,
        #d8d6c7 21px
      );
    }
  }
}

.licence-display {
  margin: 60px 0 0 0;
  text-decoration: none;

  a {
    font-weight: bold;
    color: black;
    text-decoration: none;
  }
}

.stop {
  background: #f64e4e;
  background-image: url(../../assets/img/icon/stop.svg) !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 50%;
  transform: translateY(3px);
  outline: none;
}
</style>
